.App{
  margin: auto;
}

/* Products Details Page */
.product-details-container {
  display: flex;
}
.product-details-container > div {
  padding: 5%;
}
.product-details-left{
  width:50%
}
.product-details-right{
  width:50%
}
.v1{
  border-left: 3px solid black;
  height: 50%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 10%;

}


/* Previous and next button on products list page */
.carousel-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*margin-top: 10px; !* Adjust the margin as needed *!*/
}

.prev-button,
.next-button {
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none;
  padding: 8px 16px; /* Adjust padding as needed */
  cursor: pointer;
  border-radius: 4px; /* Add rounded corners if desired */
  /*margin-bottom: 10%;*/

}

.prev-button:hover,
.next-button:hover {
  background-color: #0056b3; /* Hover background color */
}

.prev-button {
  margin-right: 10px; /* Spacing between buttons */
  margin-top: 0;
}
/*----------------------------------------------------------------------------------------*/

/*Search-Bar*/
.Search-Bar{
  width: 15%;
  float: right;
  margin-top: 1%;
}

@media only screen and (max-width: 600px) {
  .Search-Bar{
    width: 60%;
    float: right;
    margin-top: 5%;
  }

  .burger-menu{
    float: right;
    font-size: 30px;
    margin-top: 4%;
    color: white;
    margin-right: 10px;
  }
}